export const ACTION_TYPES = {

    //Restaurant
    SET_RESTAURANT_DATA: "SET_RESTAURANT_DATA",

    //products
    SET_PRODUCT: "SET_PRODUCT",
    GET_RESTAURANT_PRODUCTS: "GET_RESTAURANT_PRODUCTS",
    DELETE_PRODUCTS: "DELETE_PRODUCTS",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",

    //Categories
    SET_CATEGORY: "SET_PRODUCT",
    GET_RESTAURANT_CATEGORIES: "GET_RESTAURANT_CATEGORIES",
    DELETE_CATEGORIES: "DELETE_CATEGORIES",
    UPDATE_CATEGORY: "UPDATE_CATEGORY",

    //Orders
    GET_ORDERS: "GET_ORDERS",
    DELETE_ORDERS: "DELETE_ORDERS",
    UPDATE_ORDERS: "UPDATE_ORDERS",

    //drivers
    GET_UNAPPROVED_DRIVERS: "GET_UNAPPROVED_DRIVERS",
    UPDATE_DRIVER_STATUS: "UPDATE_DRIVER_STATUS",
    
    //about Us
    SET_ABOUT_US: "SET_ABOUT_US",
    GET_ALL_ABOUT_US: "GET_ALL_ABOUT_US",
    UPDATE_ABOUT_US: "UPDATE_ABOUT_US",
    DELETE_ABOUT_US: "DELETE_ABOUT_US",

    //progress
    PROGRESS_BAR_STATE:"PROGRESS_BAR_STATE",

    //login
    LOGIN : "LOGIN"
}